<script lang="ts" setup>
const store = useMainStore();
const toast = useCustomToast();
const { t } = useI18n();

const visibleInstructionDialog = ref(false);

const isNotificationEnabled = computed(() => store.notification || store.user.isEmailNotificationsEnabled);

const toggleWantSexStatus = () => {
  store.user.wantSex = !store.user.wantSex;

  store
    .saveWantSex()
    .then(() => {
      toast.add({
        severity: 'success',
        summary: t('want_sex_updating_success'),
        life: 3000,
      });
    })
    .catch(() => {
      toast.add({
        severity: 'error',
        summary: t('want_sex_updating_error'),
        life: 3000,
      });
    });
};
</script>

<template>
  <Dialog
    v-model:visible="store.sexStatusWidgetDialogVisible"
    style="width: 360px"
    dismissable-mask
    :base-z-index="5"
    :draggable="false"
    modal
    class="sexStatusWidget__dialog"
  >
    <template #header>
      <div class="flex gap-2 items-center">
        <span class="p-dialog-title">{{ t('title') }}</span>

        <Button
          @click="visibleInstructionDialog = true"
          icon="pi pi-question-circle"
          text
          rounded
          class="w-1.5rem h-1.5rem"
        />
      </div>
    </template>

    <div class="text-lg text-center">
      <p v-if="store.user.wantSex" class="mt-0">
        {{ t('you_want_sex.0') }}
        <span style="color: var(--success-color)">{{ t('want') }}</span>
        {{ t('you_want_sex.1') }}
      </p>
      <p v-else class="mt-0">
        {{ t('you_not_ready_for_sex.0') }}
        <span style="color: var(--error-color)">{{ t('not_ready') }}</span>
        {{ t('you_not_ready_for_sex.1') }}
      </p>

      <p v-if="store.user.pairedUser?.wantSex">
        {{ t('your_partner_wants_sex.0') }}
        <span style="color: var(--success-color)">{{ t('want') }}</span>
        {{ t('your_partner_wants_sex.1') }}
      </p>
      <p v-else>
        {{ t('your_partner_is_not_ready_for_sex.0') }}
        <span style="color: var(--error-color)">{{ t('not_ready') }}</span>
        {{ t('your_partner_is_not_ready_for_sex.1') }}
      </p>
    </div>

    <div class="flex justify-content-around">
      <div class="flex items-center flex-col">
        <Button text class="sexStatusWidget__toggle mb-2">
          <BaseFireIcon
            :active="store.user.wantSex"
            class="w-6rem"
            @click="toggleWantSexStatus"
          />
        </Button>
        {{ t('you') }}
      </div>
      <div class="flex items-center flex-col">
        <BaseFireIcon
          :active="store.user.pairedUser?.wantSex"
          class="w-6rem mb-2"
        />
        {{ t('partner') }}
      </div>
    </div>

    <span class="flex items-center mt-4">
      <span class="mr-2 flex-shrink-1 text-base xl:text-lg" style="flex: 1">
        {{ t('change_want_sex') }}
      </span>
      <ToggleSwitch
        class="ml-auto"
        :model-value="store.user.wantSex"
        @update:model-value="toggleWantSexStatus"
      />
    </span>

    <template #footer>
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="store.sexStatusWidgetDialogVisible = false"
        text
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="visibleInstructionDialog"
    :header="t('instruction_title')"
    style="width: 420px"
    dismissable-mask
    :base-z-index="6"
    :draggable="false"
    modal
  >
    <p class="leading-6 mt-0">
      {{ t('instruction_description') }}
    </p>
    <NuxtImg
      src="/images/instructions/want-sex-notification.png"
      format="webp"
      loading="lazy"
      class="w-full h-auto"
      width="370"
      height="196"
      style="aspect-ratio: 370 / 196"
    />
    <p class="leading-6">
      {{ t('instruction_actions_title') }}
    </p>
    <ul class="pl-0">
      <li class="flex gap-3 items-center mb-3">
        <i
          v-if="store.isAuthorized && store.user.pairedUser"
          class="pi pi-check-circle text-xl"
          style="color: #9fdaa8"
        />
        <i v-else class="pi pi-times-circle text-xl" style="color: #f19ea6" />
        <span>
          {{
            store.isAuthorized && store.user.pairedUser
              ? t('instruction_actions.0.success')
              : t('instruction_actions.0.failed')
          }}
        </span>
      </li>
      <li class="flex gap-3 items-center mb-3">
        <i
          v-if="isNotificationEnabled"
          class="pi pi-check-circle text-xl"
          style="color: #9fdaa8"
        />
        <i v-else class="pi pi-times-circle text-xl" style="color: #f19ea6" />
        <span>
          {{
            isNotificationEnabled
              ? t('instruction_actions.1.success')
              : t('instruction_actions.1.failed')
          }}
        </span>
      </li>
    </ul>
    <p class="leading-6 mb-0">
      {{ t('instruction_hint') }}
    </p>

    <template #footer>
      <NuxtLink to="/profile">
        <Button
          :label="t('instruction_to_profile')"
          @click="
            visibleInstructionDialog = false;
            store.sexStatusWidgetDialogVisible = false;
          "
          text
        />
      </NuxtLink>
      <Button
        :label="t('close')"
        icon="pi pi-times"
        @click="visibleInstructionDialog = false"
        text
      />
    </template>
  </Dialog>
</template>

<i18n>
en:
  title: Sex desire status
  want: want
  not_ready: not ready
  you_want_sex:
    - You
    - to have sex
  you_not_ready_for_sex:
    - You are
    - to have sex!
  your_partner_wants_sex:
    - Your partner
    - to have sex
  your_partner_is_not_ready_for_sex:
    - Your partner is
    - for sex
  you: You
  partner: Partner
  change_want_sex: 'Change sex desire status:'
  close: Close
  instruction_title: How it works
  instruction_description: Desire Status is the ability to tell your partner that you want to have sex today. When you
    make your status active, your partner will receive a notification on their device.
  instruction_actions_title: 'Required Actions:'
  instruction_actions:
    - success: Partner added
      failed: You have not added a partner
    - success: Notifications enabled
      failed: You didn't enable notifications
  instruction_hint: Everything you need can be configured in your profile.
  instruction_to_profile: Profile
  want_sex_updating_success: Your wish has been updated successfully
  want_sex_updating_error: An error occurred while updating the wish

ru:
  title: Статус желания
  want: желаете
  not_ready: не готовы
  you_want_sex:
    - Вы
    - заняться сексом
  you_not_ready_for_sex:
    - Вы
    - заняться сексом
  your_partner_wants_sex:
    - Ваш партнер
    - заняться сексом
  your_partner_is_not_ready_for_sex:
    - Ваш партнер
    - на секс
  you: Вы
  partner: Партнер
  change_want_sex: 'Изменить статус желания:'
  close: Закрыть
  instruction_title: Как это работает
  instruction_description: Статус желания - это возможность сообщить вашему партнеру о вашем желании заняться сегодня
    сексом. Когда вы сделаете свой статус активным, ваш партнер получит уведомление об этом на своем устройстве.
  instruction_actions_title: 'Необходимые действия:'
  instruction_actions:
    - success: Партнер добавлен
      failed: Вы не добавили партнера
    - success: Уведомления включены
      failed: Вы не включили уведомления
  instruction_hint: Все необходимое можно настроить в вашем профиле.
  instruction_to_profile: Профиль
  want_sex_updating_success: Ваше желание успешно обновлено
  want_sex_updating_error: Во время обновления желания произошла ошибка
</i18n>

<style lang="scss">
.sexStatusWidget {
  &__toggle {
    padding: 0;
    border: 0 !important;
    border-radius: 50%;
  }

  &__dialog {
    --success-color: #9fdaa8;
    --error-color: #f19ea6;
  }
}
</style>
