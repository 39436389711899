<script setup lang="ts">
type MainMenuItem = {
  label?: string;
  divider?: boolean;
  to?: string;
  click?: (event: MouseEvent) => void;
  icon?: string;
  testId?: string;
};

const store = useMainStore();
const { t } = useI18n();

const MOBILE_MENU: MainMenuItem[] = [
  { label: t('navigation.home'), to: store.isPWA ? '/dashboard' : '/' },
  { divider: true },
  { label: t('navigation.about_sex_tasks'), to: '/about-fants' },
  {
    label: t('navigation.play_sex_tasks'),
    to: '/game/settings',
    testId: 'menu-game-link',
  },
  {
    label: t('navigation.custom_tasks'),
    to: '/game/custom',
    testId: 'menu-custom-tasks-link',
  },
  { divider: true },
  { label: t('navigation.about_interests_game'), to: '/about-fants#interests' },
  { label: t('navigation.play_in_the_interests'), to: '/interests' },
  { label: t('navigation.show_matches'), to: '/interests/results/compare' },
  { label: t('navigation.select_questions'), to: '/interests/packs' },
  { label: t('navigation.edit_answers'), to: '/interests/change' },
  { divider: true },
  { label: t('navigation.articles'), to: '/articles' },
];

const showMenu = ref(false);
</script>

<template>
  <Button
    icon="pi pi-bars"
    text
    plain
    class="lg:!hidden"
    @click="showMenu = !showMenu"
    :aria-label="t('open')"
    data-test-id="toggle-mobile-menu"
  />

  <BaseSidePanel v-model:visible="showMenu">
    <template v-for="item in MOBILE_MENU">
      <Divider :key="`divider_${item.to}`" v-if="item.divider" />
      <NuxtLink
        :key="`link_${item.to}`"
        v-else
        :to="item.to"
        class="block w-full"
        :data-test-id="item.testId"
      >
        <Button
          plain
          text
          :label="item.label"
          size="large"
          class="text-center w-full"
        />
      </NuxtLink>
    </template>
  </BaseSidePanel>
</template>

<i18n src="~/locales/navigation.yaml"></i18n>

<i18n>
en:
  open: Open menu

ru:
  open: Открыть меню
</i18n>
