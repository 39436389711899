<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();

const visible = defineModel<boolean>('visible');

const isMoved = ref(false);
const touchStartXPosition = ref<number>(0);
const touchDeltaXPosition = ref<number>(0);
const menuContainer = ref<HTMLDivElement>();

const normalizeXPosition = (event: TouchEvent | MouseEvent) => {
  return 'touches' in event ? event.touches[0].clientX : event.clientX;
};

const onTouchMove = (event: TouchEvent | MouseEvent) => {
  const xPosition = normalizeXPosition(event);

  touchDeltaXPosition.value = Math.min(
    0,
    xPosition - touchStartXPosition.value,
  );
  isMoved.value = true;
};

const onTouchEnd = (event: Event) => {
  if (isMoved.value) event.preventDefault();
  if (touchDeltaXPosition.value < -150) {
    setTimeout(() => {
      visible.value = false;
    });
  }
  touchStartXPosition.value = 0;
  touchDeltaXPosition.value = 0;
  setTimeout(() => {
    isMoved.value = false;
  });

  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchmove', onTouchMove);
    menuContainer.value.removeEventListener('mousemove', onTouchMove);
    menuContainer.value.removeEventListener('touchend', onTouchEnd);
    menuContainer.value.removeEventListener('mouseup', onTouchEnd);
  }
};

const onTouchStart = (event: TouchEvent | MouseEvent) => {
  if (!visible.value) return;

  touchStartXPosition.value = normalizeXPosition(event);

  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchmove', onTouchMove);
    menuContainer.value.addEventListener('mousemove', onTouchMove);
    menuContainer.value.addEventListener('touchend', onTouchEnd);
    menuContainer.value.addEventListener('mouseup', onTouchEnd);
  }
};

const onOverflowClick = () => {
  if (!isMoved.value) visible.value = false;
};

watch(() => route.fullPath, () => {
  visible.value = false;
});

watch(visible, (value) => {
  if (value) document.body.classList.add('overflow-hidden');
  else document.body.classList.remove('overflow-hidden');
});

onMounted(() => {
  if (menuContainer.value) {
    menuContainer.value.addEventListener('touchstart', onTouchStart, {
      passive: true,
    });
    menuContainer.value.addEventListener('mousedown', onTouchStart, {
      passive: true,
    });
  }
});

onBeforeUnmount(() => {
  if (menuContainer.value) {
    menuContainer.value.removeEventListener('touchstart', onTouchStart);
    menuContainer.value.removeEventListener('mousedown', onTouchStart);
  }
});
</script>

<template>
  <div ref="menuContainer">
    <Transition name="fade">
      <div
        v-if="visible"
        class="fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-10 transition"
        @click="onOverflowClick"
      />
    </Transition>
    <Transition name="sidebar">
      <aside
        v-if="visible"
        class="bg-surface-900 h-screen flex flex-col select-none top-0 left-0 fixed z-20 w-72"
        :style="
          touchStartXPosition
            ? `transform: translateX(${touchDeltaXPosition}px)`
            : 'transition: transform 0.25s ease'
        "
      >
        <Button
          @click="visible = false"
          icon="pi pi-times"
          severity="secondary"
          text
          rounded
          class="!absolute top-4 right-4"
          :aria-label="t('close')"
        />
        <nav
          class="overflow-y-auto min-h-full p-2 py-8 flex flex-col justify-center gap-2"
        >
          <slot />
        </nav>
      </aside>
    </Transition>
  </div>
</template>

<i18n>
en:
  close: Close menu

ru:
  close: Закрыть меню
</i18n>
